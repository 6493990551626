import { Pipe, PipeTransform } from '@angular/core';

/**
 * use after `roStateGroup` pipe
 *
 * @example {{ (value | roStateGroup | budgetOs) }}
 */
@Pipe({
  name: 'budgetOs'
})
export class BudgetOsPipe implements PipeTransform {

  transform(value: string): "budget" | "ro" {
    switch (value) {
      case "budget":
      case "rejected":
      case "closed-budget":
        return 'budget';
      default:
        return "ro";
    }

  }

}
